import { Icon } from '/features/buildingBlocks/Icon'

import styles from './CtaButton.css'

export function CtaButtonPrimary({ children, icon = undefined, dataX, onClick = undefined, href = undefined, layoutClassName = undefined }) {
  const CtaButtonBase = href ? ABase : ButtonBase
  return <CtaButtonBase className={cx(styles.componentPrimary, layoutClassName)} {...{ children, icon, dataX, onClick, href }} />
}

export function CtaButtonSecondary({ children, dataX, onClick = undefined, href = undefined }) {
  const CtaButtonBase = href ? ABase : ButtonBase
  return <CtaButtonBase className={styles.componentSecondary} {...{ children, dataX, onClick, href }} />
}

export function CtaButtonGhost({ children, icon = undefined, dataX, onClick = undefined, href = undefined }) {
  const CtaButtonBase = href ? ABase : ButtonBase
  return <CtaButtonBase className={styles.componentGhost} {...{ children, icon, dataX, onClick, href }} />
}

function ABase({ className, dataX, icon = undefined, children, onClick, href }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a className={cx(styles.componentABase, className)} {...{ href, onClick, rel, target }} data-x={dataX}>
      {icon && <Icon layoutClassName={styles.icon} {... { icon }} />}
      {children}
    </a>
  )
}

function ButtonBase({ className, dataX, icon = undefined, children, onClick }) {
  return (
    <button className={cx(styles.componentButtonBase, className)} {...{ onClick }} data-x={dataX} type='button'>
      {icon && <Icon layoutClassName={styles.icon} {... { icon }} />}
      {children}
    </button>
  )
}
