import { PortableText } from '@portabletext/react'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'

import styles from './PortableText.css'

export function PortableTextMainContent({ value }) {
  return (
    <PortableTextBase
      className={styles.componentMainContent}
      components={componentsMainContent}
      {...{ value }}
    />
  )
}

export function PortableTextCookieBar({ value }) {
  return (
    <PortableTextBase
      className={styles.componentCookieBar}
      components={componentsCookieBar}
      {...{ value }}
    />
  )
}

function PortableTextBase({ value, components, className }) {
  return <div {...{ className }}><PortableText {...{ value, components }} /></div>
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
const componentsMainContent = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    heading: ({ children }) => <h2 className={styles.heading}>{children}</h2>,
    subheading: ({ children }) => <h3 className={styles.subheading}>{children}</h3>,
  },
  list: {
    bullet: ({ children }) => <ul className={cx(styles.list, styles.listUnordered)}>{children}</ul>,
    number: ({ children }) => <ol className={cx(styles.list, styles.listOrdered)}>{children}</ol>,
  },
  listItem: (props) => <li className={styles.listItem}>{props.children}</li>,
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => <a href={value.href} data-x='link' className={styles.link} target='_blank' rel="noopener noreferrer">{children}</a>,
    internalLink: ({ value, children }) => value.ref
      ? <a href={determineDocumentPathSync({ document: value.ref, routeMap })} data-x='link' className={styles.link}>{children}</a>
      : <>{children}</>,
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
const componentsCookieBar = {
  block: {
    normal: ({ children }) => <p className={cx(styles.paragraph, styles.paragraphCookieBar)}>{children}</p>,
  },
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => <a href={value.href} data-x='link' className={styles.link} target='_blank' rel="noopener noreferrer">{children}</a>,
    internalLink: ({ value, children }) => value.ref
      ? <a href={determineDocumentPathSync({ document: value.ref, routeMap })} data-x='link' className={cx(styles.link, styles.strong)}>{children}</a>
      : <>{children}</>,
  },
}
